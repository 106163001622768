<!-- 招生系统基础配置 -- 中考考试科目管理 -- 新增 -->
<template>
	<edit-template style="padding-top: 48rem" @confirm="submit" @cancel="$confirm('取消新增?','提示',{type:'warning'}).then(()=>$router.back()).catch(()=>{});" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
		<el-form ref="form" :model="form" :rules="rules" label-width="150rem" style="margin-left:  22rem;margin-bottom: 100rem;min-height: calc(100vh - 412rem)">
			<el-form-item label="省份" prop="province">
				<el-select v-model="form.province" placeholder="请选择" @change="getCity">
					<el-option v-for="item in provinceOptions" :label="item.address_name" :value="item.address_code"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="城市" prop="city">
				<el-select v-model="form.city" placeholder="请选择" @change="getArea">
					<el-option v-for="item in cityOptions" :label="item.address_name" :value="item.address_code"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="区/县" prop="area">
				<el-select v-model="form.area" placeholder="请选择">
					<el-option v-for="item in areaOptions" :label="item.address_name" :value="item.address_code"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="考试年份" prop="year">
				<el-select v-model="form.year" placeholder="请选择">
					<el-option v-for="item in yearOptions" :label="item" :value="item"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="记分方式" prop="score_type">
				<el-select v-model="form.score_type" placeholder="请选择" @change="onScoreType">
					<el-option v-for="(item,index) of score_typeOptions" :disabled="score_typeOptionsDisabled[index]" :label="item" :value="index"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="总分数" prop="total_score">
				<el-input v-model="form.total_score" maxlength="10" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="总分等级" prop="total_level" v-if="form.city == '512000'">
				<el-input v-model="form.total_level" maxlength="10" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="普高线分数" prop="score_line">
				<el-input v-model="form.score_line" maxlength="10" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="普高线等级" prop="socre_level" v-if="form.city == '512000'">
				<el-input v-model="form.socre_level" maxlength="10" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item v-if="form.city === '510100'" label="自主划线分数" prop="freedom_line">
				<el-input v-model="form.freedom_line" maxlength="10" placeholder="请输入"></el-input>
			</el-form-item>

			<h4 style="padding: 50rem 0 20rem 30rem">配置中考科目和单科满分</h4>

			<div v-for="(item, index) in form.subject" v-if="form.score_type === '0' || form.score_type === '3'" :key="index" class="knowledge">
				<div style="background: #f4f4f4;padding: 20rem 40rem 20rem 0;display: flex">
					<el-form-item label="考试科目" label-width="100rem" style="margin-bottom: 0">
						<el-select v-model="item.subject_id" :popper-append-to-body="true" @change="subjectChangeTwo(index)">
							<el-option v-for="item in subjectOptions" :disabled="item.disabled" :label="item.subject_name" :value="item.id">
						</el-option></el-select>
					</el-form-item>
					<el-form-item :prop="'subject.'+index+'.score'" label="单科成绩满分" label-width="140rem" style="margin-bottom: 0">
						<el-input v-model="item.score" maxlength="10" placeholder="请输入"></el-input>
					</el-form-item>
					<el-form-item v-if="subjectOptions.find(obj => obj.id === item.subject_id)&amp;&amp;subjectOptions.find(obj => obj.id === item.subject_id).subject_name === '英语'" :prop="'subject.'+index+'.score'" label="招收最低分" label-width="140rem" style="margin-bottom: 0">
						<el-input v-model="item.min_score" maxlength="10" placeholder="请输入"></el-input>
					</el-form-item>
					<br>
				</div>
				<div class="flex" style="margin-left: 40rem">
					<el-button v-if="form.subject.length !== 1" circle="" icon="el-icon-delete" @click="deleteKnowledge(index)"></el-button>
					<el-button circle="" icon="el-icon-plus" @click="addKnowledge"></el-button>
				</div>
			</div>
			<div v-if="form.score_type === '1'||form.score_type === '2'" style="display: flex;flex-wrap: wrap">
				<div v-for="(item,index) in form.subject" style="width: 300rem;height: 300rem;border: 1rem solid #CCCCCC; margin: 12rem;display:flex;justify-content: center;align-items: center;flex-direction: column">
					<el-form label-width="100rem">
						<el-form-item label="科目">
							{{ subjectOptions.find(obj =&gt; obj.id === item.subject_id).subject_name }}
						</el-form-item>
						<el-form-item label="单科满分">
							{{ item.level }}
						</el-form-item>
						<el-form-item label="等级数">
							{{ item.subject_level.length }}
						</el-form-item>
					</el-form>
					<div>
						<el-button type="primary" @click="onEdit(index)">编辑</el-button>
						<el-button @click="()=>form.subject.splice(index, 1)">删除</el-button>
					</div>
				</div>
			</div>
			<div v-if="form.score_type === '1'||form.score_type === '2'" style="padding: 0 40rem 20rem 60rem">
				<el-button @click="addIndividualSubjectGrades">添加单科等级分</el-button>
			</div>
		</el-form>

		


		<!--等级新增-->
		<el-dialog :before-close="onLevelDialogCancel" :close-on-click-modal="false" :visible.sync="levelDialog" title="添加单科等级分" width="30%">
			<el-form :model="form" label-width="80rem">
				<el-form-item label="科目">
					<el-select v-model="subjectData.subject_id" placeholder="请选择" @change="subjectChange">
						<el-option v-for="item in subjectOptions" :disabled="item.disabled" :label="item.subject_name" :value="item.id">
					</el-option></el-select>
				</el-form-item>
				<el-form-item label="单科满分">
					<el-input v-model="subjectData.level" maxlength="10" placeholder="请输入等级"></el-input>
					<el-input v-if="form.score_type === '2'" v-model="subjectData.score" maxlength="10" placeholder="请输入分数" style="margin-top: 12rem"></el-input>
				</el-form-item>
			</el-form>
			<div style="height: 300rem;overflow: scroll">
				<div v-for="(item,index) in subjectData.subject_level" style="display: flex;align-items: center;justify-content: center">
					<div style="background: #f4f4f4;padding: 20rem 40rem; display: flex; align-items: center;margin-bottom:12rem">
						<div style="width: 24rem">{{ index + 1 }}</div>
						<el-input v-model="item.level" placeholder="请输入等级" style="margin: 0 12rem"></el-input>
						<el-input v-if="form.score_type === '2'" v-model="item.score" placeholder="请输入分数"></el-input>
						<el-checkbox v-if="(subjectOptions.find(obj => {return obj.id === subjectData.subject_id}) || {}).subject_name === '英语'" v-model="item.is_disabled_recruit" style="margin-left: 12rem">此档位不招收</el-checkbox>
					</div>
					<div style="display: flex;margin-left: 12rem">
						<el-button v-if="subjectData.subject_level.length !== 1" circle="" icon="el-icon-delete" @click="deleteKnowledge(index)"></el-button>
						<el-button circle="" icon="el-icon-plus" @click="addKnowledgeTwo"></el-button>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="onLevelDialogCancel">取 消</el-button>
				<el-button type="primary" @click="onLevelDialogSure">确 定</el-button>
			</span>
		</el-dialog>

		<!--等级编辑-->
		<el-dialog v-if="subject_index !== null" :before-close="()=>levelDialogTwo=false" :close-on-click-modal="false" :visible.sync="levelDialogTwo" title="添加单科等级分" width="30%">
			<el-form :model="form" label-width="80rem">
				<el-form-item label="科目">
					<el-select v-model="editData.subject_id" placeholder="请选择" @change="editDataSubjectChange">
						<el-option v-for="item in subjectOptions" :disabled="item.disabled" :label="item.subject_name" :value="item.id">
					</el-option></el-select>
				</el-form-item>
				<el-form-item label="单科满分">
					<el-input v-model="editData.level" maxlength="10" placeholder="请输入等级"></el-input>
					<el-input v-if="form.score_type === '2'" v-model="editData.score" maxlength="10" placeholder="请输入分数" style="margin-top: 12rem"></el-input>
				</el-form-item>
			</el-form>
			<div style="height: 300rem;overflow: scroll">
				<div v-for="(item,index) in editData.subject_level" style="display: flex;align-items: center;justify-content: center">
					<div style="background: #f4f4f4;padding: 20rem 40rem; display: flex; align-items: center;margin-bottom:12rem">
						<div style="width: 24rem">{{ index + 1 }}</div>
						<el-input v-model="item.level" placeholder="请输入等级" style="margin: 0 12rem"></el-input>
						<el-input v-if="form.score_type === '2'" v-model="item.score" placeholder="请输入分数"></el-input>
						<el-checkbox v-if="(subjectOptions.find(obj => {return obj.id === editData.subject_id}) || {}).subject_name === '英语'" v-model="item.is_disabled_recruit" style="margin-left: 12rem">此档位不招收</el-checkbox>
					</div>
					<div style="display: flex;margin-left: 12rem">
						<el-button v-if="editData.subject_level.length !== 1" circle="" icon="el-icon-delete" @click="deleteKnowledgeTwo(index)"></el-button>
						<el-button circle="" icon="el-icon-plus" @click="addKnowledgeTwoTwo"></el-button>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
						<el-button @click="()=>levelDialogTwo=false">取 消</el-button>
						<el-button type="primary" @click="onTest">确 定</el-button>
					</span>
		</el-dialog>
	
</edit-template>
</template>

<script>
export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
		return {
			backType: 0,
			form: {
				province: '',
				city: '',
				area: '',
				total_score: '',
				score_type: '',
				score_line: '',
				freedom_line: '',
				total_level: '',
				socre_level: '',
				subject: [],
			},
			subjectData: {},
			rules: {
				province: [{required: true, message: "请选择省份"}],
				city: [{required: true, message: "请选择城市"}],
				area: [{required: true, message: "请选择区/县"}],
				year: [{required: true, message: "请选择考试年份"}],
				score_type: [{required: true, message: "请选择记分方式"}],
				total_score: [{required: true, message: "请填写总分数"}],
				score_line: [{required: true, message: "请填写普高线分数"}],
				freedom_line: [{required: true, message: "请填写自主划线分数"}],
				total_level: [{required: true, message: "请填写总分等级"}],
				socre_level: [{required: true, message: "请填写普高线等级"}],
			},
			provinceOptions: [],
			cityOptions: [],
			areaOptions: [],
			subjectOptions: [],
			yearOptions: [],
			score_typeOptionsDisabled: [false, false, false, true],
			score_typeOptions: {
				0: '分数',
				1: '等级',
				2: '分数+等级',
				3: '总分等级单科分数'
			},
			levelDialog: false,
			levelDialogTwo: false,
			subject_index: null,
			editData: {},
		}
	},
	created() {
		this.getProvince()
		this.getSubject()
		// 获取年份配置前端自己通过new Data()来获取前两个和后年总共五年年份
		this.yearOptions = [new Date().getFullYear() - 2, new Date().getFullYear() - 1, new Date().getFullYear(), new Date().getFullYear() + 1, new Date().getFullYear() + 2]
	},
	watch: {
		form: {
			deep: true,
			handler(val) {
				let arr = val.subject.map(item => item.subject_id)
				this.subjectOptions.forEach(item => {
					item.disabled = arr.includes(item.id)
				})
			}
		}
	},
	methods: {
		// 所选科目变更
		subjectChangeTwo(val) {
			this.form.subject[val].min_score = ''
		},
		// 所选科目变更
		subjectChange() {
			this.subjectData.subject_level.forEach(item => {
				item.is_disabled_recruit = false
			})
		},
		// 所选科目变更
		editDataSubjectChange() {
			this.editData.subject_level.forEach(item => {
				item.is_disabled_recruit = false
			})
		},
		// 添加单科等级分
		addIndividualSubjectGrades() {
			if (!this.form.score_type) {
				return this.$message.error('请先选择记分方式')
			}
			this.levelDialog = true
		},

		getSubject() {
			this.$_register('/api/recruit/examination/subject').then(res => {
				let data = res.data.data
				if (data.length) {
					this.subjectOptions = data.map(item => {
						return {
							...item,
							disabled: false
						}
					})
				}
			})
		},

		// 添加科目
		addKnowledge() {
			this.form.subject.push({subject_id: '', score: '', min_score: ''})
		},
		// 添加科目
		addKnowledgeTwo() {
			if (this.subjectData.subject_level.length >= 50) {
				this.$message.error('最多添加50条')
				return
			}
			if (this.form.score_type === '2') this.subjectData.subject_level.push({level: '', score: '', is_disabled_recruit: false})
			else this.subjectData.subject_level.push({level: '', is_disabled_recruit: false})
			this.$forceUpdate()
		},
		// 添加科目
		addKnowledgeTwoTwo() {
			if (this.editData.subject_level.length >= 50) {
				this.$message.error('最多添加50条')
				return
			}
			if (this.form.score_type === '2') this.editData.subject_level.push({level: '', score: '', is_disabled_recruit: false})
			else this.editData.subject_level.push({level: '', is_disabled_recruit: false})
			this.$forceUpdate()
		},
		// 编辑科目等级分
		onEdit(index) {
			this.editData = this.deepCopy(this.form.subject[index])
			this.subject_index = index
			this.levelDialogTwo = true
		},

		// 记分方式变更
		onScoreType(vla) {
			if (vla === '2') {
				this.form.subject = []
				this.subjectData = {subject_id: '', level: '', score: '', subject_level: [{level: '', score: '', is_disabled_recruit: false}]}
			}
			if (vla === '1') {
				this.form.subject = []
				this.subjectData = {subject_id: '', level: '', subject_level: [{level: '', is_disabled_recruit: false}]}
			}
			if (vla === '0' || vla === '3') {
				this.form.subject = [{subject_id: '', score: '', min_score: ''}]
			}
		},
		// 取消按钮
		onLevelDialogCancel() {
			this.levelDialog = false
			if (this.form.score_type === '2') {
				this.subjectData = {subject_id: '', level: '', score: '', subject_level: [{level: '', score: '', is_disabled_recruit: false}]}
			}
			if (this.form.score_type === '1') {
				this.subjectData = {subject_id: '', level: '', subject_level: [{level: '', is_disabled_recruit: false}]}
			}
		},
		// 确定按钮
		onLevelDialogSure() {
			if (this.form.score_type === '2') {
				for (const item in this.subjectData) {
					if (item === 'subject_id') {
						if (this.subjectData.subject_id === '') {
							return this.$message.error('请选择科目')
						}
					}
					if (item === 'level') {
						if (this.subjectData.level === '') {
							return this.$message.error('请填写等级')
						}
					}
					if (item === 'score') {
						if (this.subjectData.score === '') {
							return this.$message.error('请填写分数')
						}
					}
					if (item === 'subject_level') {
						for (let i = 0; i < this.subjectData.subject_level.length; i++) {
							if (this.subjectData.subject_level[i].level === '') {
								return this.$message.error('请填写等级')
							}
							if (this.subjectData.subject_level[i].score === '') {
								return this.$message.error('请填写分数')
							}
						}
					}
				}
				this.form.subject.push(this.subjectData)
				this.subjectData = {subject_id: '', level: '', score: '', subject_level: [{level: '', score: ''}]}
			}
			if (this.form.score_type === '1') {
				for (const item in this.subjectData) {
					if (item === 'subject_id') {
						if (this.subjectData.subject_id === '') {
							return this.$message.error('请选择科目')
						}
					}
					if (item === 'level') {
						if (this.subjectData.level === '') {
							return this.$message.error('请填写等级')
						}
					}
					if (item === 'subject_level') {
						for (let i = 0; i < this.subjectData.subject_level.length; i++) {
							if (this.subjectData.subject_level[i].level === '') {
								return this.$message.error('请填写等级')
							}
						}
					}
				}
				this.form.subject.push(this.subjectData)
				this.subjectData = {subject_id: '', level: '', subject_level: [{level: ''}]}
			}
			this.levelDialog = false
		},

		// 编辑确定
		onTest() {
			this.$set(this.form.subject, this.subject_index, this.editData);
			this.levelDialogTwo = false
			this.$forceUpdate();
		},

		deepCopy(obj) {
			if (typeof obj !== 'object' || obj === null) {
				return obj;
			}
			let result = Array.isArray(obj) ? [] : {};
			for (let key in obj) {
				if (obj.hasOwnProperty(key)) {
					result[key] = this.deepCopy(obj[key]);
				}
			}
			return result;
		},

		//获取省份
		getProvince() {
			this.$_register('/api/recruit/common/area', {params: {address_code: '000000'}}).then(res => {
				this.provinceOptions = res.data.data
			})
		},

		//获取城市
		getCity(e) {
			this.cityOptions = []
			this.form.city = ''
			this.areaOptions = []
			this.form.area = ''
			this.$_register('/api/recruit/common/area', {params: {address_code: e}}).then(res => {
				this.cityOptions = res.data.data
			})
		},

		//获取区/县
		getArea(e) {
			this.areaOptions = []
			this.form.area = ''
			this.$_register('/api/recruit/common/area', {params: {address_code: e}}).then(res => {
				this.areaOptions = res.data.data
			})
			if (e === '512000') {
				this.form.score_type = '3'
        this.form.subject = [{subject_id: '', score: '', min_score: ''}]
				this.score_typeOptionsDisabled[0] = true
				this.score_typeOptionsDisabled[1] = true
				this.score_typeOptionsDisabled[2] = true
				this.score_typeOptionsDisabled[3] = false
				return
			}
			if (e === '510100') {
				this.form.score_type = ''
				this.score_typeOptionsDisabled[0] = false
				this.score_typeOptionsDisabled[1] = true
				this.score_typeOptionsDisabled[2] = true
				this.score_typeOptionsDisabled[3] = true
			} else {
				this.form.score_type = ''
				this.score_typeOptionsDisabled[0] = false
				this.score_typeOptionsDisabled[1] = false
				this.score_typeOptionsDisabled[2] = false
				this.score_typeOptionsDisabled[3] = true
			}
		},

		// 删除科目
		deleteKnowledge(index) {
			if (this.form.score_type === '0'||this.form.score_type === '3') this.form.subject.splice(index, 1)
			else this.subjectData.subject_level.splice(index, 1)
			this.$forceUpdate()
		},
		// 删除科目
		deleteKnowledgeTwo(index) {
			this.editData.subject_level.splice(index, 1)
			this.$forceUpdate()
		},

		// 提交数据
		submit() {
			this.$refs.form.validate((res) => {
				if (res) {
					let params = {...this.form}
					this.$_register.post("/api/recruit/examination", params).then(res => {
						this.$message({
							type: 'success',
							message: res.data.msg,
							onClose: () => {
								this.$store.commit("setPage", 1);
								this.backType = 1
								this.$router.back();
							}
						});
					})
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>

.form {
	padding-top: 70rem;
}

//
//::v-deep .el-form .el-input {
//	min-width: 100rem !important;
//}

@media (max-width: 1550rem) {
	/* 在屏幕宽度小于等于1400rem时应用的样式 */
	/* 在这里添加你的样式规则 */
	::v-deep .el-form .el-input {
		width: 190rem !important;
	}
}

.knowledge {
	//padding-left: 60rem;
	display: flex;
	align-items: center;
	width: 100%;
	margin-top: 24rem;

	.knowledge_box {
		display: flex;
		padding-top: 12rem;
		height: 60rem;
		background-color: #f4f4f4;
	}

	.add_knowledge {
		display: flex;
		padding-left: 40rem;
	}
}

</style>
